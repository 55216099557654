import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Logo, LogoService, LogoType } from '@app/core';

@Component({
  selector: 'app-logos',
  templateUrl: './logos.component.html',
  styleUrls: ['./logos.component.scss']
})
export class LogosComponent implements OnInit {
  @Input()
  public id?: string;

  @Output() 
  public logosData: EventEmitter<Logo[] | undefined> = new EventEmitter<Logo[] | undefined>();

  @Output() 
  public logoUploadedReceived: EventEmitter<void> = new EventEmitter<void>();

  public logos?: Logo[];
  public logoTypes: any[] = LogoType.get();

  constructor(private logoService: LogoService) {
  }

  ngOnInit(): void {
    this.logoService.get(this.id!).subscribe((logos: Logo[]) => {
      this.logos = logos;
      this.logosData.emit(this.logos);
    });
  }

  public getLogoByType(type: string): Logo | null {
    return this.logos?.find(l => l.logoType === type) ?? null;
  }

  public logoUploaded(imgUrl){
    this.logoUploadedReceived.emit(imgUrl);
  }
}
