import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  Product,
  SubscriptionType,
  selectDependentValidation,
  selectValidationRules
} from '@app/core';
import { convertUTCDateToLocalDate } from '@app/shared';
import { Periods } from 'app/modules/core/constants/periods.const';

@Component({
  selector: 'app-create-subscription-dialog',
  templateUrl: './create-subscription-dialog.component.html',
  styleUrls: ['./create-subscription-dialog.component.scss']
})
export class CreateSubscriptionDialogComponent {

  public formGroup: FormGroup;
  public periods = Periods.get();
  public types = SubscriptionType.get();
  public searchControl = new FormControl();
  public companyId: string;
  public products: Product[];
  public currentLang: string;
  public selectedProducts: Product[] = [];
  public selectedProductCategories!: any[];
  public filteredProducts: any;
  public selectedCategories: any;
  public groupedProducts: { [key: string]: Product[]; };

  constructor(
    private dialogRef: MatDialogRef<CreateSubscriptionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { companyId: string, products: Product[], currentLang: string }
  ) {
    this.selectedCategories = [];
    this.selectedProductCategories = [];
    this.companyId = data.companyId;
    this.products = data.products;
    this.filteredProducts = this.products.slice();
    this.groupedProducts = this.groupProductsByCategory(this.products)
    this.currentLang = data.currentLang;
    this.formGroup = new FormGroup({
      products: new FormControl('', [Validators.required,]),
      name: new FormControl('', [ Validators.required ]),
      companyId: new FormControl(this.companyId ? this.companyId : '', [ Validators.required ]),
      billingType: new FormControl('', [ Validators.required ]),
      type: new FormControl('', [ Validators.required ]),
      billingDate: new FormControl('', [ Validators.required ]),
      price: new FormControl('', [ Validators.required ]),
      minVolume: new FormControl('', [ Validators.required ])
    });
    this.formGroup.get('products')!.setAsyncValidators(selectDependentValidation(selectValidationRules, this.products));

    this.searchControl.valueChanges.subscribe((searchTerm) => {
      this.filteredProducts = this.filterProductsBySearchTerm(searchTerm);
       });
  }

  public onSelectionChange(): void {
    const selectedProductIds = this.formGroup.get('products')?.value || [];
    this.selectedProducts = this.products.filter((product) => selectedProductIds.includes(product.id));
  
    this.selectedProductCategories = Array.from(new Set(this.selectedProducts.map((product) => product.productCategory)));
  }

  public filteredProductsInGroup(categoryProducts: any): Product[] {
    const filteredProducts = categoryProducts.value.filter((product) => this.filteredProducts?.includes(product));
    if (filteredProducts.length > 0 && !this.selectedCategories.includes(categoryProducts.key)) {
      this.selectedCategories.push(categoryProducts.key);
    }
    return filteredProducts;
  }

  private filterProductsBySearchTerm(searchTerm: string): Product[] {
    if (!searchTerm) {
      return this.products.slice();
    }
    return this.products.filter((product) =>
      product.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }
  
  public groupProductsByCategory(products: Product[]): { [key: string]: Product[] } {
    const groupedProducts: { [key: string]: Product[] } = {};
  
    for (const product of products) {
      if (!groupedProducts[product.productCategory]) {
        groupedProducts[product.productCategory] = [];
      }
      groupedProducts[product.productCategory].push(product);
    }
    return groupedProducts;
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public onSave(): void {
    if (this.formGroup.invalid) {
      return;
    }
    const request = this.formGroup.getRawValue();
    request.billingDate = convertUTCDateToLocalDate(new Date(request.billingDate));
    this.dialogRef.close(request);
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.formGroup.controls[controlName].hasError(errorName);
  }

  public getControlByName = (controlName: string) => {
    return this.formGroup.controls[controlName];
  }

}
