<div>
    <h5 mat-dialog-title>{{ 'create' | translate }}</h5>
    <div mat-dialog-content>
        <form [formGroup]="formGroup" fxLayout="column">
            <!-- Name -->
            <mat-form-field color="accent">
                <input matInput placeholder="{{ 'name' | translate }}" formControlName="name">
                <mat-error *ngIf="hasError('name', 'required')">
                    {{ 'requiredError' | translate }}
                </mat-error>
            </mat-form-field>
            <!-- Product Id -->
             <mat-form-field color="accent" fxFlex.gt-sm="48">
                <mat-select formControlName="products" [placeholder]="'products' | translate" [multiple]="true" #multiSelect (selectionChange)="onSelectionChange()">
                    <mat-select-trigger>           
                        <span class="example-additional-selection">
                            {{ selectedProductCategories.join(', ') }}
                          </span>
                      </mat-select-trigger>
                    <mat-option>
                      <ngx-mat-select-search
                        [formControl]="searchControl"
                        [placeholderLabel]="'searchProducts' | translate"
                        [noEntriesFoundLabel]="'noProductsWasFound' | translate"
                      ></ngx-mat-select-search>
                    </mat-option>
                    <ng-container *ngFor="let categoryProducts of groupedProducts | keyvalue">
                        <mat-optgroup *ngIf="filteredProductsInGroup(categoryProducts)" [label]="categoryProducts.key | translate">
                          <mat-option *ngFor="let product of filteredProductsInGroup(categoryProducts)" [value]="product.id">
                            {{ product.type | translate }}
                          </mat-option>
                        </mat-optgroup>
                      </ng-container>
                  </mat-select>
                <mat-error *ngIf="hasError('products', 'required')">
                    {{ 'requiredError' | translate }}
                </mat-error>
                <mat-error *ngIf="hasError('products', 'required')">
                    {{ 'requiredError' | translate }}
                </mat-error>
                <mat-error *ngIf="hasError('products', 'KycIDProof')">
                    {{ 'aiOCRProductIsRequired' | translate }}
                </mat-error>
                <mat-error *ngIf="hasError('products', 'KycLiveness')">
                    {{ 'aiActiveLivenessProductIsRequired' | translate }}
                </mat-error>
                <mat-error *ngIf="hasError('products', 'KycExternalDataProvider')">
                    {{ 'aiDataWHProductIsRequired' | translate }}
                </mat-error>
                <mat-error *ngIf="hasError('products', 'KycAMLSanction')">
                    {{ 'aiAMLSanctionProductIsRequired' | translate }}
                </mat-error>
                <mat-error *ngIf="hasError('products', 'KycAMLPEP')">
                    {{ 'aiAMLPEPProductIsRequired' | translate }}
                </mat-error>
            </mat-form-field>

            <!-- Billing Date  -->
            <mat-form-field color="accent" fxFlex.gt-sm="48">
                <mat-label>{{ 'billingDate' | translate }}</mat-label>
                <input matInput [matDatepicker]="billDatePicker" formControlName="billingDate">
                <mat-datepicker-toggle matSuffix [for]="billDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #billDatePicker></mat-datepicker>
                <mat-error *ngIf="hasError('billingDate', 'required')">
                    {{ 'requiredError' | translate }}
                </mat-error>
            </mat-form-field>

            <!-- Billing Type -->
            <mat-form-field color="accent" fxFlex.gt-sm="48">
                <mat-label>{{ 'billingType' | translate }}</mat-label>
                <mat-select formControlName="billingType">
                    <mat-option *ngFor="let p of periods" [value]="p">{{ p | translate }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="hasError('billingType', 'required')">
                    {{ 'requiredError' | translate }}
                </mat-error>
            </mat-form-field>

            <!-- Type -->
            <mat-form-field color="accent" fxFlex.gt-sm="48">
                <mat-label>{{ 'type' | translate }}</mat-label>
                <mat-select formControlName="type">
                    <mat-option *ngFor="let t of types" [value]="t">{{ t | translate }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="hasError('type', 'required')">
                    {{ 'requiredError' | translate }}
                </mat-error>
            </mat-form-field>

            <!-- Price -->
            <mat-form-field color="accent" fxFlex.gt-sm="48">
                <input type="number" matInput placeholder="{{ 'price' | translate }}" formControlName="price">
                <mat-error *ngIf="hasError('price', 'required')">
                    {{ 'requiredError' | translate }}
                </mat-error>
            </mat-form-field>

            <!-- MinVolume -->
            <mat-form-field color="accent" fxFlex.gt-sm="48">
                <input type="number" matInput placeholder="{{ 'minVolume' | translate }}" formControlName="minVolume">
                <mat-error *ngIf="hasError('minVolume', 'required')">
                    {{ 'requiredError' | translate }}
                </mat-error>
            </mat-form-field>
        </form>
    </div>

    <!-- Actions -->
    <div mat-dialog-actions fxLayoutAlign="end">
        <button mat-button (click)="onCancel()">{{ 'cancel' | translate }}</button>
        <button mat-raised-button (click)="onSave()" color="accent">{{ 'save' | translate }}</button>
    </div>
</div>