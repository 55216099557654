import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { CategoriesService, Category, Language, PagedResult } from '@app/core';
import { Observable } from 'rxjs';
import { Regex } from '../../validators';

@Component({
  selector: 'app-create-company-dialog',
  templateUrl: './create-company-dialog.component.html',
  styleUrls: ['./create-company-dialog.component.scss']
})
export class CreateCompanyDialogComponent implements OnInit {

  public languages: Language[];
  public formGroup?: FormGroup;

  public categories$?: Observable<PagedResult<Category>>;

  constructor(
    private categoriesService: CategoriesService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CreateCompanyDialogComponent>
  ) {
    this.languages = data.languages;
  }

  ngOnInit(): void {
    // TODO: Dont use limit: xxxxx
    this.categories$ = this.categoriesService.getCategories({start: 0, limit: 100000});
    this.initializeFormGroup();
  }

  private initializeFormGroup(): void {
    const nameTranslations = new FormGroup({});
    this.languages.forEach(language => {
      nameTranslations.addControl(language.id, new FormControl(null, Validators.required));
    });
    this.formGroup = new FormGroup({
      nameTranslations: nameTranslations,
      typeOfClient: new FormControl(null, Validators.required),
      email: new FormControl(null, [
        Validators.required,
        Validators.maxLength(320),
        Validators.email
      ]),
      phone: new FormControl(null, [
        Validators.required,
        Validators.maxLength(20),
        Validators.pattern(Regex.phone)
      ]),
      website: new FormControl(null, [
        Validators.required,
        Validators.pattern(Regex.url),
      ]),
      agentUrl: new FormControl(null, [
        Validators.required,
        Validators.pattern(Regex.url),
      ]),
      did: new FormControl(null, Validators.required),
      verKey: new FormControl(null, Validators.required),
      categories: new FormControl(null)
    });
  }

  public get nameTranslations(): FormGroup {
    return this.formGroup?.get('nameTranslations') as FormGroup;
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public onSave(): void {
    this.formGroup?.markAllAsTouched();
    if (this.formGroup?.invalid) {
      return;
    }
    const company = this.formGroup?.getRawValue();
    this.dialogRef.close({company: company, typeOfClient: company.typeOfClient});
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.formGroup?.controls[controlName].hasError(errorName);
  }
}
