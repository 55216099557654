import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Logo } from '@app/core';

@Component({
  selector: 'app-company-logos',
  templateUrl: './company-logos.component.html',
  styleUrls: ['./company-logos.component.scss']
})
export class CompanyLogosComponent {

  @Input()
  public id?: string;
  @Output() public logosData: EventEmitter<Logo[] | undefined> = new EventEmitter<Logo[] | undefined>();
  public receivedLogos?: Logo[];
  @Output() logoUploaded: EventEmitter<void> = new EventEmitter<void>();

  public receiveLogosData(logos: Logo[] | undefined): void {
    this.logosData.emit(logos);
  }
  public logoUploadedReceived(imgUrl){
    this.logoUploaded.emit(imgUrl);
  }
}
