import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Logo, LogoService, SnackBarService } from '@app/core';

@Component({
  selector: 'app-logo-upload',
  templateUrl: './logo-upload.component.html',
  styleUrls: ['./logo-upload.component.scss']
})
export class LogoUploadComponent {
  @Output()
  public logoUploaded: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  public id?: string;

  @Input()
  public type?: any;

  @Input()
  public logo?: Logo;

  public file?: string;

  constructor(
    private logoService: LogoService,
    private snackBarService: SnackBarService) {}


  public onImageChanged(e: any) {
    this.file = e
  }

  public submit() {
    if (!this.file) {
      return;
    }
    if(this.logo?.url){
      this.uploadNew()
    } else {
      this.upload()
    }
  }

  private upload(){
    const formData = new FormData();
    formData.append('file', this.file!);
    formData.append('type', this.type.name);
    this.logoService.create(this.id!, formData).subscribe((logo) => {
      this.snackBarService.openSuccess('imageUploaded')
      this.logo = logo;
    },
    () => {
      this.snackBarService.openError('failedToUpload')
    });
  }

  public receivedImgUrl(imgUrl){
    this.logoUploaded.emit(imgUrl);
  }

  private uploadNew(){
    const formData = new FormData();
    formData.append('file', this.file!);
    this.logoService.update(this.id!, this.logo!.id, formData).subscribe((logo) => {
        this.snackBarService.openSuccess('imageUploaded');
      this.logo = logo;
      this.logoUploaded.emit();
    },
    () => {
      this.snackBarService.openError('failedToUpload')
    });
  }

}
